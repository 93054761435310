
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'WatchlistAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    return {
      route,
      router,
      store,
      mixins,
      conf,
    }
  },
  data(): {
    editable: any
    form: any
    composing: boolean
  } {
    return {
      editable: null,
      form: {
        file: null,
        alias: null,
      },
      composing: false,
    }
  },
  computed: {
    isAdd(): any {
      return !('pwatch_id' in this.data)
    },
    validation() {
      let error = ''
      if (!this.editable.name) {
        error += 'ウォッチリスト名が未入力です'
      }
      if (!this.editable.source && !this.editable.url) {
        error += '画像が選択されていません'
      }
      if (!this.editable.checked_face) {
        error += '登録できない画像です'
      }
      return error
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.editable.other_name_ = this.editable.other_name ? this.editable.other_name.split(',') : []
    this.editable.url = this.editable.url || null

    // URLがあれば判定
    if (this.editable.url) {
      const checked = await this.mixins.checkPwatchImg(this.editable)
      this.editable.checked_face = checked.checked_face
    } else {
      this.editable.checked_face = null
    }
  },
  methods: {
    async add() {
      // 入力中のものがある
      if (this.form.alias && !confirm('入力中のデータがありますがよろしいですか？')) { return }
      // 入力チェック
      if (this.validation) {
        alert(this.validation)
        return
      }
      // console.log(this.editable)
      const res = await this.mixins.addPWatch(this.editable)
      // console.log(res)
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
    change(e: any) {
      this.editable.file = e.target.files[0]
      this.getDataURL(this.editable.file)
      // this.$set(this.edit.data, 'file', file)
    },
    drop(e: any) {
      e.target.classList.remove('on')
      // const URL = URL || webkitURL
      this.editable.file = e.dataTransfer.files[0]
      this.getDataURL(this.editable.file)
      // this.$set(this.edit.data, 'file', file)
    },
    async getDataURL(file: any) {
      this.editable.checked_face = null
      this.editable.checked_face_data = null
      // Promise関数を用意
      const readFile = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.readAsDataURL(file)
        })
      }
      // 同期処理
      readFile().then((res: any) => {
        // return res
        this.editable.source = res
        // this.editable.img = res.split(',')[1]
        // this.$set(this.edit.data, 'icon', res.split(',')[1])
        // this.$set(this.edit.data, 'source', res)
        // this.checkFaceImage()
        // console.log(res)
      })

      // ファイルの値をセット
      this.editable.filename = file.name
      const checked = await this.mixins.checkPwatchImg(this.editable)
      this.editable.checked_face = checked.checked_face
      this.editable.checked_face_data = checked
    },
    getFrameStyle() {
      const pictData = this.editable.checked_face_data
      const previewW = 240
      const ratio = previewW / pictData.imageW
      return {
        width: `${pictData.width * ratio}px`,
        height: `${pictData.height * ratio}px`,
        left: `${pictData.xPos * ratio}px`,
        top: `${pictData.yPos * ratio}px`,
      }
    },
    addTag() {
      if (this.composing) { return }
      if (this.form.alias) {
        this.editable.other_name_.push(this.form.alias)
        this.editable.other_name = this.editable.other_name_.join(',')
        this.form.alias = null
        // console.log('hoge')
      }
    },
    removeTag(i: string) {
      this.editable.other_name_.splice(i, 1)
      this.editable.other_name = this.editable.other_name_.join(',')
    },
  },
})
