<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">コピーを作成</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <!-- 追加する場所 -->
        <div class="location">
          <div class="path"><span class="icon"><i class="material-icons">movie</i></span><span class="text">マイプロジェクト</span></div>
        </div>
        <div class="list">
          <div class="item" v-for="(project, i) in projects" :class="{on: project.selected}" :key="i" @click="selectProject(i)">
            <div class="path">
              <span class="icon"><i class="material-icons">movie</i></span>
              <span class="text">{{ project.project_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" :class="{disabled: !direction}" @click="update">複製</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'WatchlistCopy',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    const store = useStore()
    const mixins = new Mixins()
    let selects = Object.assign([], store.getters.selectedWatchlists)
    if (!selects.length) {
      selects = [props.data]
    }
    return {
      store,
      mixins,
      selects,
    }
  },
  data(): {
    editable: any
    projects: any
    current: any
  } {
    return {
      editable: null,
      projects: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    direction(): any {
      if (!this.current.project) {
        return null
      } else {
        return this.current.project
      }
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    const projects = await this.mixins.getListProject()
    this.projects = projects.filter((project: any) => project.project_id !== this.data.project_id && project.user_type !== 2)
  },
  methods: {
    async update() {
      const res = await this.mixins.copyPWatch({
        direction: this.direction,
        selects: this.selects,
      })
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder) {
        if (this.current.folder.parent_folder_id) {
          this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
        } else {
          this.current.folder = null
        }
      } else {
        this.current.project = null
      }
    },
    selectProject(i: number) {
      if (this.projects[i].selected) {
        this.projects[i].selected = false
        this.current.project = null
      } else {
        this.projects.forEach((project: any) => { project.selected = false })
        this.projects[i].selected = true
        this.current.project = this.projects[i]
      }
    },
    // async openProject(i: number) {
    //   this.current.project = await this.mixins.getProject(this.projects[i])
    // },
    // selectFolder(i: number) {
    //   if (this.list[i].selected) {
    //     this.list[i].selected = false
    //   } else {
    //     this.current.project.folders.forEach((folder: any) => { folder.selected = false })
    //     this.list[i].selected = true
    //   }
    // },
    // openFolder(i: number) {
    //   this.current.project.folders.forEach((folder: any) => { folder.selected = false })
    //   this.current.folder = this.list[i]
    // },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

.location
  display grid
  grid-template auto / 24px 1fr
  align-items center
  grid-gap $gap * .5
  & + .list
    margin-top $gap * .5
  .path
    grid-column 2
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    .text
      ellipsis()

.list
  height 250px
  border-top 1px solid #bababa
  border-bottom 1px solid #bababa
  background-color #F7F7F7
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em $gap
    padding-left calc(24px + .5rem)
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3
    cursor pointer
    user-select none

    &.on
      background-color $color-active
      color #fff

    .path
      display grid
      grid-template auto / auto 1fr auto
      grid-gap .5em
      align-items center
      .text
        ellipsis()
  //
  &:empty
    &::after
      content 'コピー可能なプロジェクトがありません'
      display block
      padding $gap $gap * 1.7

//


</style>
