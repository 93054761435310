<template>
  <dialog-add v-if="dialog.method === 'add'" :data="dialog.data" @close="close" @update="update"></dialog-add>
  <dialog-edit v-else-if="dialog.method === 'edit'" :data="dialog.data" @close="close" @update="update"></dialog-edit>
  <dialog-delete v-else-if="dialog.method === 'delete'" :data="dialog.data" @close="close" @update="update"></dialog-delete>
  <dialog-copy v-else-if="dialog.method === 'copy'" :data="dialog.data" @close="close" @update="update"></dialog-copy>
</template>

<script>
import { defineComponent } from 'vue'
import Mixins from '@/mixins/mixins'

// Dialog
// import DialogShare from '@/components/dialog/ProjectShare.vue'
// import DialogEdit from '@/components/dialog/ProjectEdit.vue'
// import DialogDelete from '@/components/dialog/ProjectDelete.vue'
import DialogAdd from '@/components/dialog/WatchlistAdd.vue'
import DialogEdit from '@/components/dialog/WatchlistEdit.vue'
import DialogDelete from '@/components/dialog/WatchlistDelete.vue'
import DialogCopy from '@/components/dialog/WatchlistCopy.vue'

export default defineComponent({
  name: 'DialogsFolder',
  components: {
    'dialog-add': DialogAdd,
    'dialog-edit': DialogEdit,
    'dialog-delete': DialogDelete,
    'dialog-copy': DialogCopy,
  },
  props: ['dialog'],
  setup() {
    const mixins = new Mixins()
    return {
      mixins,
    }
  },
  data() {
    return {
      editable: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    close() {
      this.$emit('close')
    },
    update() {
      this.$emit('update')
    },
  },
})
</script>

<style lang="stylus" scoped>
</style>
