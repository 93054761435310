
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'WatchlistEdit',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    return {
      route,
      router,
      store,
      mixins,
      conf,
    }
  },
  data(): {
    editable: any
    form: any
  } {
    return {
      editable: null,
      form: {
        file: null,
        alias: null,
      },
    }
  },
  computed: {
    validation() {
      let error = ''
      if (!this.editable.name) {
        error += 'ウォッチリスト名が未入力です'
      }
      return error
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.editable.other_name_ = this.editable.other_name ? this.editable.other_name.split(',') : []
  },
  methods: {
    async update() {
      // 入力中のものがある
      if (this.form.alias && !confirm('入力中のデータがありますがよろしいですか？')) { return }
      // 入力チェック
      if (this.validation) {
        alert(this.validation)
        return
      }
      // console.log(this.editable)
      const res = await this.mixins.updatePWatch(this.editable)
      // console.log(res)
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
    addTag() {
      if (this.form.alias) {
        this.editable.other_name_.push(this.form.alias)
        this.editable.other_name = this.editable.other_name_.join(',')
        this.form.alias = null
        console.log('hoge')
      }
    },
    removeTag(i: string) {
      this.editable.other_name_.splice(i, 1)
      this.editable.other_name = this.editable.other_name_.join(',')
    },
  },
})
