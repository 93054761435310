
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'WatchlistCopy',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    const store = useStore()
    const mixins = new Mixins()
    let selects = Object.assign([], store.getters.selectedWatchlists)
    if (!selects.length) {
      selects = [props.data]
    }
    return {
      store,
      mixins,
      selects,
    }
  },
  data(): {
    editable: any
    projects: any
    current: any
  } {
    return {
      editable: null,
      projects: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    direction(): any {
      if (!this.current.project) {
        return null
      } else {
        return this.current.project
      }
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    const projects = await this.mixins.getListProject()
    this.projects = projects.filter((project: any) => project.project_id !== this.data.project_id && project.user_type !== 2)
  },
  methods: {
    async update() {
      const res = await this.mixins.copyPWatch({
        direction: this.direction,
        selects: this.selects,
      })
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder) {
        if (this.current.folder.parent_folder_id) {
          this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
        } else {
          this.current.folder = null
        }
      } else {
        this.current.project = null
      }
    },
    selectProject(i: number) {
      if (this.projects[i].selected) {
        this.projects[i].selected = false
        this.current.project = null
      } else {
        this.projects.forEach((project: any) => { project.selected = false })
        this.projects[i].selected = true
        this.current.project = this.projects[i]
      }
    },
    // async openProject(i: number) {
    //   this.current.project = await this.mixins.getProject(this.projects[i])
    // },
    // selectFolder(i: number) {
    //   if (this.list[i].selected) {
    //     this.list[i].selected = false
    //   } else {
    //     this.current.project.folders.forEach((folder: any) => { folder.selected = false })
    //     this.list[i].selected = true
    //   }
    // },
    // openFolder(i: number) {
    //   this.current.project.folders.forEach((folder: any) => { folder.selected = false })
    //   this.current.folder = this.list[i]
    // },
  },
})
