<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">新規ウォッチリストを作成</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <div class="box">
          <label class="label">写真</label>
          <div class="input pict" :class="{ok: editable.checked_face === true, ng: editable.checked_face === false}">
            <label
              v-if="!editable.url && !editable.source"
              class="drop"
              @dragenter.prevent="$event.target.classList.add('on')"
              @dragover.prevent=""
              @dragleave.prevent="$event.target.classList.remove('on')"
              @drop.prevent="drop"
            >
              <i class="material-icons">add_circle</i>
              <input type="file" @change="change" />
            </label>
            <picture v-else-if="editable.url" class="thumb">
              <img :src="`${conf.IMGBASE}${editable.url}`" />
            </picture>
            <picture
              v-else-if="editable.source"
              class="thumb"
              @dragenter.prevent="$event.target.classList.add('on')"
              @dragover.prevent=""
              @dragleave.prevent="$event.target.classList.remove('on')"
              @drop.prevent="drop"
            >
              <img :src="`${editable.source}`" />
              <div class="frame" v-if="editable.checked_face_data">
                <div class="item" :style="getFrameStyle()"></div>
              </div>
            </picture>
          </div>
        </div>
        <div class="box">
          <label class="label">名前</label>
          <div class="input text">
            <input type="text" v-model="editable.name" placeholder="名前を入力してください">
          </div>
        </div>
        <div class="box">
          <label class="label">別名</label>
          <div class="input tag">
            <input type="text" v-model="form.alias" placeholder="別名を入力してください" @keydown.enter="addTag" @compositionstart="composing = true" @compositionend="composing = false">
            <span class="button" @click="addTag"><i class="material-icons">add_circle</i></span>
          </div>
          <ul class="list tag" v-if="editable.other_name_.length">
            <li class="item" v-for="(item, i) in editable.other_name_" :key="i">
              <span class="text">{{ item }}</span>
              <span class="icon" @click="removeTag(i)"><i class="material-icons">remove_circle</i></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="memo">追加／変更したウォッチリストの反映には<br>素材の再解析が必要となります</div>
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="add" :class="{disabled: !editable.checked_face || !editable.name}">作成</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'WatchlistAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    return {
      route,
      router,
      store,
      mixins,
      conf,
    }
  },
  data(): {
    editable: any
    form: any
    composing: boolean
  } {
    return {
      editable: null,
      form: {
        file: null,
        alias: null,
      },
      composing: false,
    }
  },
  computed: {
    isAdd(): any {
      return !('pwatch_id' in this.data)
    },
    validation() {
      let error = ''
      if (!this.editable.name) {
        error += 'ウォッチリスト名が未入力です'
      }
      if (!this.editable.source && !this.editable.url) {
        error += '画像が選択されていません'
      }
      if (!this.editable.checked_face) {
        error += '登録できない画像です'
      }
      return error
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.editable.other_name_ = this.editable.other_name ? this.editable.other_name.split(',') : []
    this.editable.url = this.editable.url || null

    // URLがあれば判定
    if (this.editable.url) {
      const checked = await this.mixins.checkPwatchImg(this.editable)
      this.editable.checked_face = checked.checked_face
    } else {
      this.editable.checked_face = null
    }
  },
  methods: {
    async add() {
      // 入力中のものがある
      if (this.form.alias && !confirm('入力中のデータがありますがよろしいですか？')) { return }
      // 入力チェック
      if (this.validation) {
        alert(this.validation)
        return
      }
      // console.log(this.editable)
      const res = await this.mixins.addPWatch(this.editable)
      // console.log(res)
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
    change(e: any) {
      this.editable.file = e.target.files[0]
      this.getDataURL(this.editable.file)
      // this.$set(this.edit.data, 'file', file)
    },
    drop(e: any) {
      e.target.classList.remove('on')
      // const URL = URL || webkitURL
      this.editable.file = e.dataTransfer.files[0]
      this.getDataURL(this.editable.file)
      // this.$set(this.edit.data, 'file', file)
    },
    async getDataURL(file: any) {
      this.editable.checked_face = null
      this.editable.checked_face_data = null
      // Promise関数を用意
      const readFile = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.readAsDataURL(file)
        })
      }
      // 同期処理
      readFile().then((res: any) => {
        // return res
        this.editable.source = res
        // this.editable.img = res.split(',')[1]
        // this.$set(this.edit.data, 'icon', res.split(',')[1])
        // this.$set(this.edit.data, 'source', res)
        // this.checkFaceImage()
        // console.log(res)
      })

      // ファイルの値をセット
      this.editable.filename = file.name
      const checked = await this.mixins.checkPwatchImg(this.editable)
      this.editable.checked_face = checked.checked_face
      this.editable.checked_face_data = checked
    },
    getFrameStyle() {
      const pictData = this.editable.checked_face_data
      const previewW = 240
      const ratio = previewW / pictData.imageW
      return {
        width: `${pictData.width * ratio}px`,
        height: `${pictData.height * ratio}px`,
        left: `${pictData.xPos * ratio}px`,
        top: `${pictData.yPos * ratio}px`,
      }
    },
    addTag() {
      if (this.composing) { return }
      if (this.form.alias) {
        this.editable.other_name_.push(this.form.alias)
        this.editable.other_name = this.editable.other_name_.join(',')
        this.form.alias = null
        // console.log('hoge')
      }
    },
    removeTag(i: string) {
      this.editable.other_name_.splice(i, 1)
      this.editable.other_name = this.editable.other_name_.join(',')
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

  &-content
    display grid
    grid-template auto 1fr / 240px 1fr
    grid-gap $gap
    // border-top 1px solid #E6E6E6
    // border-bottom 1px solid #E6E6E6
    // padding $gap 0
    .box:nth-child(1)
      grid-row span 2
      grid-column 1
  &-footer
    grid-template auto \/ 1fr auto auto
    .memo
      color crimson
      font-size .8rem
      line-height 1.5
.label
  line-height 2
.input
  &.pict
    .drop
      display grid
      width 240px
      height 240px
      background-color #F4F4F4
      cursor pointer
      i
        margin auto
      input
        display none
      &.on
        background-color $color-key1
    .thumb
      position relative
      display block
      img
        pointer-events none
      &.on
        opacity .5
    //
    .frame
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      .item
        position absolute
        box-shadow 0 0 0 1px #fff inset, 0 0 0 2px #000 inset, 0 0 0 1px #000
    //
    &.ok
      &::after
        content '登録可能な画像です'
        display block
        color $color-main
        line-height 2
    &.ng
      &::after
        content '登録できない画像です'
        display block
        color crimson
        line-height 2

  //
  &.text
    background-color #F4F4F4
    border-bottom 1px solid #BABABA
    padding 0 .5em
    input
      display block
      width 100%
      line-height 50px
      &::placeholder
        color #aaa
  //
  &.tag
    display grid
    grid-template auto / 1fr auto
    background-color #F4F4F4
    border-bottom 1px solid #BABABA
    padding 0 .5em
    input
      display block
      width 100%
      line-height 50px
      &::placeholder
        color #aaa
    .button
      margin auto
//
.list
  &.tag
    margin-top 1em
    .item
      display inline-flex
      padding 0 .5em
      line-height 2
      border-radius 1em
      box-shadow 0 0 0 1px #BABABA
      margin-right .5em
      margin-bottom .5em
      .icon
        margin-left .5em
        cursor pointer
      // & + .item
        // margin-left .5em


</style>
