
import { defineComponent, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import DialogsWatchlist from '@/components/dialog/DialogsWatchlist.vue'
import DialogProjectShare from '@/components/dialog/ProjectShare.vue'
export default defineComponent({
  name: 'WatchList',
  components: {
    'dialogs-watchlist': DialogsWatchlist,
    'dialog-share': DialogProjectShare,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    const project = ref(store.getters.project)

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      conf,
      project,
      isPowerUser,
      isSuperUser,
    }
  },
  data(): {
    context: any
    add: any
    share: any
    edit: any
    del: any
    move: any
    copy: any
    ui: any
    list: any
    dialog: any,
  } {
    return {
      context: null,
      add: null,
      share: null,
      edit: null,
      del: null,
      move: null,
      copy: null,
      ui: {
        viewType: 1, // 1: リスト, 2: グリッド
        add: false,
        sort: {
          key: 'name', // ソートキー
          ascend: true, // true: 昇順, false: 降順
        },
      },
      list: [],
      dialog: {
        type: null,
        method: null,
        data: null,
      },
    }
  },
  computed: {
    list_(): any {
      let list_: any
      if (this.list) {
        list_ = this.list.filter((pwatch: any) => {
          pwatch.select = false
          if (pwatch.name || pwatch.other_name) {
            let target = pwatch.name || ''
            if (pwatch.other_name) {
              target += pwatch.other_name.split(',').join('')
            }
            return this.mixins.matchRegExp(target, this.watchlistQuery.name)
          } else {
            return false
          }
        })
      } else {
        list_ = []
      }
      // ソート
      list_.sort((a: any, b: any) => {
        if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
          switch (this.ui.sort.key) {
            case 'created_at': {
              const a_ = new Date(a[this.ui.sort.key]).getTime()
              const b_ = new Date(b[this.ui.sort.key]).getTime()
              return a_ - b_
            }
            default: {
              return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
            }
          }
        } else {
          return -1
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        list_.reverse()
      }
      return list_
    },
    watchlistQuery: {
      get(): any {
        return this.store.getters.filtersWatchlistQuery
      },
      set(query): any {
        this.store.commit('setFiltersWatchlistQuery', query)
      },
    },
    selectedWatchlists: {
      get(): any {
        return this.store.getters.selectedWatchlists
      },
      set(query): any {
        this.store.commit('setSelectedWatchlists', query)
      },
    },
    allcheck(): any {
      return this.list_.every((box: any) => box.select)
    },
  },
  watch: {
    'ui.add': function() {
      if (this.ui.add) {
        console.log('true')
        window.addEventListener('click', this.closeAddMenu)
      } else {
        console.log('false')
        window.removeEventListener('click', this.closeAddMenu)
      }
    },
  },
  async beforeMount() {
    if (!this.project) {
      if (this.route.params.project_id) {
        const currentProject = {
          project_id: parseInt(this.route.params.project_id as string),
        }
        this.project = await this.mixins.getProject(currentProject)
      } else {
        this.$router.push('/')
      }
    }
    this.refresh()
  },
  methods: {
    // ウォッチリストの追加・編集
    openAdd() {
      this.dialog = {
        type: 'watchlist',
        method: 'add',
        data: {
          filename: '',
          name: '',
          other_name: '',
          project_id: this.project.project_id,
        },
      }
    },
    // ウォッチリストの追加・編集
    openEdit() {
      this.dialog = {
        type: 'watchlist',
        method: 'edit',
        data: this.context.item,
      }
    },
    // ウォッチリストのコピー
    openCopy() {
      this.dialog = {
        type: 'watchlist',
        method: 'copy',
        data: this.context.item,
      }
    },
    // ウォッチリストの削除
    openDelete() {
      this.dialog = {
        type: 'watchlist',
        method: 'delete',
        data: this.context.item,
      }
    },
    // 共有設定
    openShare() {
      this.share = this.project
    },
    // ダイアログを閉じる
    closeDialog() {
      this.share = null
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
    },
    // 選択
    selectItem(e: any, item: any, index: any) {
      // 変数
      let from: any = null
      let count: any = null
      // シフトキーが押されている
      if (e.shiftKey) {
        // 前クリックがある、選択されているものがある
        if (this.ui.lastSelected !== null && this.selectedWatchlists.length) {
          const diff = index - this.ui.lastSelected
          if (Math.sign(diff) > 0) {
            from = this.ui.lastSelected
            count = diff
          } else {
            from = index
            count = diff * -1
          }
          for (let i = from; i <= from + count; i++) {
            this.list_[i].select = true
          }
        } else {
          item.select = !item.select
        }
      } else {
        const select_ = item.select
        // メタキーが押されていない
        if (!e.ctrlKey && !e.metaKey) {
          // 選択状態を解除
          this.list_.forEach((item: any, i: any) => {
            item.select = false
          })
        }
        if ((!e.ctrlKey && !e.metaKey) && this.selectedWatchlists.length > 1 && select_) {
          item.select = true
        } else {
          item.select = !select_
        }
      }
      this.ui.lastSelected = index
      this.selectedWatchlists = this.list.filter((item: any) => item.select)
      if (!this.selectedWatchlists.length) {
        this.ui.lastSelected = null
      }
      document.getSelection()?.empty()

      // item.select = !item.select
      // this.selectedWatchlists = this.list.filter((pwatch: any) => pwatch.select)
    },
    selectAllItem() {
      // console.log('hoge')
      const value = !this.allcheck
      this.list.forEach((pwatch: any) => pwatch.select = value)
    },
    // コンテキストメニューを開く
    openContextMenu(e: any, item: any, i: any) {
      // 自身の選択状態で判定
      if (!item.select) {
        // 選択状態を解除
        this.list_.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = true
      this.selectedWatchlists = this.list.filter((item: any) => item.select)
      // this.selectItem(e, item, i)
      this.context = {
        item: item,
        position: {x: e.clientX, y: e.clientY}
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () {return false}
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () {return true}
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      // console.log(x, y)
      return {left: `${x}px`, top: `${y}px`}
    },
    closeAddMenu() {
      this.ui.add = false
    },
    // ソート指定
    sort(key: string) {
      if (key === this.ui.sort.key) {
        this.ui.sort.ascend = !this.ui.sort.ascend
      } else {
        this.ui.sort.key = key
        this.ui.sort.ascend = true
      }
    },
    // リフレッシュ
    async refresh() {
      this.list = await this.mixins.getListPWatch(this.project.project_id)
      this.closeDialog()
    },
  }
})
